export const environment = {
  production: true,

  // appUrl: 'https://walaxtest.expertapps.com.sa',
  apiUrl: 'https://walaxstageapi.expertapps.com.sa/api',
  dashboar_link: "https://walaxstage.expertapps.com.sa",
  socketUrl: 'https://walaxstage.expertapps.com.sa',
  
  mapsApiKey: "",
  googleSiteKey: '6Lfm3xohAAAAALgLSfUIY8YHdzC16AjLY6wl7U9a',
  googleApiKey: '6Lfm3xohAAAAADEauzDDPzU0YVrIxWTBbi1k3AkE',
};
